<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-app id="main-app">
    <template v-if="AppReady">
      <!--<app-system-bar class="d-print-none" />-->
      <!--<app-bar-->
      <!--  :navigation.sync="navigation"-->
      <!--/>-->
      <app-snackbar
        v-if="AppSnackbar"
        class="d-print-none"
      />
      <!--<app-navigation-->
      <!--  v-model="navigation"-->
      <!--/>-->
      <v-main
        id="main-content"
        class="main-container"
      >
        <app-progress-linear
          v-if="!AppIsSmall"
          class="d-print-none"
        />
        <slide-fade-transition>
          <layout-router-view v-if="AppReady" />
        </slide-fade-transition>
        <slide-fade-transition>
          <slot v-if="AppReady" />
        </slide-fade-transition>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import LayoutRouterView from '@/layouts/LayoutRouterView'
import { MetaInfoMixin } from '@mixins'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'AppLayout',
  components: { SlideFadeTransition, LayoutRouterView },
  mixins: [MetaInfoMixin],
  data () {
    return {
      navigation: this.$vuetify.breakpoint.mdAndUp,
      // navigation: !0,
      bottomNavigation: null
    }
  },
  computed: {
    AppSnackbar: {
      set (value) {
        mapMutations('snackbar', ['setShow']).setShow.call(this, value)
      },
      get () {
        return mapGetters('snackbar', ['getShow']).getShow.call(this)
      }
    }
  },
  watch: {
    // $route: {
    //   deep: !0,
    //   immediate: !0,
    //   handler: 'checkAuthDialog'
    // },
  },
  mounted () {
  },
  methods: {}
}
</script>
<style scoped>
@media print {
  .main-container {
    background: none !important;
  }

  .v-main {
    /*padding-top: 0 !important;*/
    padding: 0 !important;
  }
}

.main-container {
  /*min-height: 90vh;*/
  /*background: url(/assets/images/bg/artwork.png);*/
  /*background-position: -125px -125px;*/
  /*padding-bottom: 50px;*/
}

.main-container > .v-main__wrap {
  /*padding-bottom: 50px;*/
}
</style>
